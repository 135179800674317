import { render, staticRenderFns } from "./recommendGoods.vue?vue&type=template&id=1b9d50ac&scoped=true&"
import script from "./recommendGoods.vue?vue&type=script&lang=js&"
export * from "./recommendGoods.vue?vue&type=script&lang=js&"
import style0 from "./recommendGoods.vue?vue&type=style&index=0&id=1b9d50ac&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b9d50ac",
  null
  
)

export default component.exports